// BOOTSTRAP CORE COMPONENTS
import Button from '../../../mdb/hhpq/mdb/js/free/button';
import Collapse from '../../../mdb/hhpq/mdb/js/bootstrap/mdb-prefix/collapse';
import Offcanvas from '../../../mdb/hhpq/mdb/js/bootstrap/mdb-prefix/offcanvas';
import Carousel from '../../../mdb/hhpq/mdb/js/free/carousel';
import Popover from '../../../mdb/hhpq/mdb/js/free/popover';
import ScrollSpy from '../../../mdb/hhpq/mdb/js/free/scrollspy';
import Tab from '../../../mdb/hhpq/mdb/js/free/tab';
import Tooltip from '../../../mdb/hhpq/mdb/js/free/tooltip';

// MDB FREE COMPONENTS
import Input from '../../../mdb/hhpq/mdb/js/free/input';
import Dropdown from '../../../mdb/hhpq/mdb/js/free/dropdown';
import Ripple from '../../../mdb/hhpq/mdb/js/free/ripple';
import Range from '../../../mdb/hhpq/mdb/js/free/range';

// MDB PRO COMPONENTS
import Animate from '../../../mdb/hhpq/mdb/js/pro/animate';
import Chart from '../../../mdb/hhpq/mdb/js/pro/charts';
import Lightbox from '../../../mdb/hhpq/mdb/js/pro/lightbox';
import Rating from '../../../mdb/hhpq/mdb/js/pro/rating';
import Sidenav from '../../../mdb/hhpq/mdb/js/pro/sidenav';
import Alert from '../../../mdb/hhpq/mdb/js/pro/alert';
import Toast from '../../../mdb/hhpq/mdb/js/pro/toast';
import Timepicker from '../../../mdb/hhpq/mdb/js/pro/timepicker';
import Navbar from '../../../mdb/hhpq/mdb/js/pro/navbar';
import InfiniteScroll from '../../../mdb/hhpq/mdb/js/pro/infinite-scroll';
import LazyLoad from '../../../mdb/hhpq/mdb/js/pro/lazy-load';
import Datepicker from '../../../mdb/hhpq/mdb/js/pro/datepicker';
import Popconfirm from '../../../mdb/hhpq/mdb/js/pro/popconfirm';
import Datatable from '../../../mdb/hhpq/mdb/js/pro/datatable';
import Stepper from '../../../mdb/hhpq/mdb/js/pro/stepper';
import Sticky from '../../../mdb/hhpq/mdb/js/pro/sticky';
import Select from '../../../mdb/hhpq/mdb/js/pro/select';
import Touch from '../../../mdb/hhpq/mdb/js/pro/touch';
import SmoothScroll from '../../../mdb/hhpq/mdb/js/pro/smooth-scroll';
import PerfectScrollbar from '../../../mdb/hhpq/mdb/js/pro/perfect-scrollbar';
import Loading from '../../../mdb/hhpq/mdb/js/pro/loading-management';
import Autocomplete from '../../../mdb/hhpq/mdb/js/pro/autocomplete';
import Modal from '../../../mdb/hhpq/mdb/js/pro/modal';
import Clipboard from '../../../mdb/hhpq/mdb/js/pro/clipboard';
import ChipsInput from '../../../mdb/hhpq/mdb/js/pro/chips';
import MultiRangeSlider from '../../../mdb/hhpq/mdb/js/pro/multi-range';
import Datetimepicker from '../../../mdb/hhpq/mdb/js/pro/date-time-picker';
import Calendar from '../../../mdb/hhpq/plugins/calendar/js/calendar';
import WYSIWYG from '../../../mdb/hhpq/plugins/wysiwyg/js/wysiwyg';

export {
  // FREE
  Button,
  Carousel,
  Collapse,
  Offcanvas,
  Dropdown,
  Input,
  Modal,
  Popover,
  ScrollSpy,
  Ripple,
  Tab,
  Tooltip,
  Range,
  // PRO
  Alert,
  Animate,
  Chart,
  Datepicker,
  Datatable,
  Lightbox,
  Navbar,
  Popconfirm,
  Rating,
  Sidenav,
  SmoothScroll,
  Timepicker,
  Toast,
  InfiniteScroll,
  LazyLoad,
  Stepper,
  Sticky,
  Select,
  Touch,
  PerfectScrollbar,
  Loading,
  Autocomplete,
  Clipboard,
  ChipsInput,
  MultiRangeSlider,
  Datetimepicker,
  // PLUGINS
  WYSIWYG,
  Calendar,
};

window.Calendar = Calendar;
window.WYSIWYG = WYSIWYG;
